.wrapper {
  position: relative;
  display: block;
  width: 100%;

  .multi-select {
    width: 100%;
    height: 50px;
    transition: all linear 0.225s;

    :global(.ant-select-selector) {
      padding: 0 2px 0 16px !important;
      transition: all linear 0.225s;
      @media screen and (max-width: 576px) {
        padding-left: 13px !important;
      }
    }

    &.error {
      border: 1px solid var(--input-error-color);
      box-shadow: inset var(--status-red-light) 0 0 0 3px, var(--status-red-light) 0 0 0 3px;

      &:focus ~ .label {
        color: var(--input-error-color);
      }
    }

    &.active {
      border: 1px solid var(--content-dark);
      box-shadow: inset transparent 0 0 0 3px, transparent 0 0 0 3px;
    }

    :global(.ant-select-arrow) {
      display: none !important;
    }
    @media screen and (max-width: 576px) {
      height: 40px;
    }
  }

  .btn {
    position: absolute;
    top: 8.5px;
    right: 48.5px;
    padding: 10px 10px 8px;
    color: var(--content-dark);
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    background: var(--border-color);
    border-radius: 7px;
    cursor: pointer;
    transition: all linear 0.225s;
    @media screen and (max-width: 576px) {
      font-size: 13px;
    }
  }

  .cards {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    align-items: center;
    box-sizing: border-box;
    margin-top: 20px;

    .card {
      display: flex;
      align-items: center;
      padding: 7px 8px 8px 10px;
      background: var(--content-dark-08);
      border-radius: 100px;

      p {
        margin: 2px 0 0;
        color: var(--content-dark);
        font-weight: 400;
        font-size: 14px;
        line-height: 15px;
        text-align: center;
        transition: all linear 0.225s;
        @media screen and (max-width: 576px) {
          font-size: 13px;
        }
      }

      .delete {
        width: 20px;
        height: 20px;
        margin-left: 6px;
        background: transparent;
        cursor: pointer;
      }
    }
  }

  .multi {
    width: 100%;

    :global(.ant-select-selector) {
      padding-left: 16px !important;
    }
  }

  .label {
    position: absolute;
    top: 17px;
    left: 16px;
    padding: 1px 5px;
    color: var(--select-placeholder-color);
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    line-height: 15px;
    background: var(--content-white);
    border-radius: 3px;
    opacity: 1;
    transition: all linear 0.225s;
    pointer-events: none;

    &.active {
      top: -13px;
      color: var(--content-dark) !important;
    }

    &.error {
      color: var(--input-error-color);
    }
    @media screen and (max-width: 576px) {
      top: 12px;
      left: 13px;
      font-size: 13px;

      &.active {
        top: -10px;
        color: var(--content-dark) !important;
      }
    }
  }

  .message {
    display: flex;
    align-items: center;
    margin-top: 4px;
    color: var(--input-error-color);

    span {
      margin-left: 5px;
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
    }
  }
}

:global(.ant-select) {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 1px solid transparent;
  border-radius: 10px;
  transition: all linear 0.225s;
}

:global(.ant-select-selection-overflow) {
  flex-wrap: nowrap;
  width: 90% !important;
  overflow-x: scroll !important;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    height: 0 !important;
  }
}

:global(.ant-select-selection-overflow-item) {
  display: inline-block;
  width: max-content;
  height: 100% !important;

  &:not(:nth-child(1)) {
    margin-left: 6px !important;
  }
}

:global(.ant-select-selection-item-content) {
  height: 15px !important;
  margin-right: 8px !important;
  color: var(--content-dark);
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  transition: all linear 0.225s;
  @media screen and (max-width: 576px) {
    font-size: 13px;
  }
}

:global(.ant-select-selection-item-remove) {
  height: 15px !important;
}

:global(.ant-select-selection-overflow-item-suffix) {
  display: block !important;
}

:global(.ant-select-single.ant-select-open .ant-select-selection-item) {
  color: var(--select-placeholder-color) !important;
}

:global(.ant-select-multiple) {
  :global(.ant-select-selection-item) {
    display: flex;
    align-items: center;
    box-sizing: border-box !important;
    // height: max-content !important;
    margin: 0 !important;
    padding: 0 !important;
    padding: 8px 9px 7px 12px !important;
    border-radius: 100px !important;
    transition: all linear 0.225s;
    @media screen and (max-width: 576px) {
      padding: 4px 7px 3px 10px !important;
    }
  }

  :global(.ant-select-selection-search) {
    left: 0 !important;
    margin: 0 !important;
  }
}

:global(.ant-select-focused) {
  border: 1px solid var(--content-dark);

  & ~ .label {
    top: -13px !important;
    color: var(--content-dark) !important;
    @media screen and (max-width: 576px) {
      top: -10px !important;
    }
  }

  &.select.error {
    box-shadow: inset transparent 0 0 0 3px, transparent 0 0 0 3px !important;
  }
}

:global(.active) {
  border: 1px solid var(--content-dark) !important;
}

:global(.ant-select-selection-placeholder) {
  left: 20px !important;
}

:global(.ant-select-selection-search) {
  left: 0 !important;
  margin: 0 !important;
}

:global(.ant-select-item-option-state) {
  height: 14px;
}

:global(.anticon-close) {
  color: var(--content-dark);
  font-size: 17px;
  transform: translateY(-6.5px);
  transition: all linear 0.225s;
  @media screen and (max-width: 576px) {
    font-size: 15px;
  }
}
