.wrapper {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  max-width: 670px;
  height: calc(100vh - 20px);
  overflow: hidden;
  background: #51fc8f;
  border-radius: 40px;
  @media (max-width: 750px) {
    height: 250px;
  }

  h1,
  p,
  a {
    margin: 0;
    padding: 0;
  }

  .dropdown {
    display: none;
    align-items: center;
    justify-content: space-between;
    width: 168px;
    min-width: 168px;
    padding: 12px 17px;
    color: var(--content-dark);
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    background-color: transparent;
    border: 1px solid #000;
    border-radius: 100px;
    cursor: pointer;
    @media (max-width: 750px) {
      display: inline-flex;
      gap: 9.5px;
    }

    .rotate-icon {
      transform: rotate(180deg) !important;
    }
  }

  .right-card {
    height: 80%;

    > img {
      height: 500px;
    }
  }

  .left-card {
    padding: 10px 50px 35px 58px;
    @media (max-width: 750px) {
      padding: 20px 0 20px 20px;
    }
    @media screen and (max-width: 900px) {
      padding: 10px 30px 35px 28px;
    }

    transition: display 20s;

    .hide {
      @media (max-width: 750px) {
        display: none;
        transition: 2s ease-in;
      }
    }

    .view {
      display: block;
      transition: 2s ease-in;
    }
    @media (max-width: 750px) {
      height: auto;
    }

    .star-card {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      height: 100px;
      margin-bottom: 14px;
      @media screen and (max-width: 1100px) {
        height: max-content;
      }
      .star {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        height: 100%;
        @media screen and (max-width: 1100px) {
          width: 45px;
          height: auto;
        }
      }

      .star:last-child {
        align-items: flex-end;
        margin-left: 50px;
        @media screen and (max-width: 1100px) {
          width: 35px;
          height: auto;
          margin-top: 35px;
        }
      }
      @media (max-width: 750px) {
        display: none;
      }
    }

    .bottom-star {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin-top: 40px;
      @media (max-width: 1051px) {
        display: none;
      }
    }

    .text-card {
      .title {
        margin-top: 16px;
        color: #000;
        font-weight: 700;
        font-size: 50px;
        font-family: 'Neue Machina', sans-serif;
        font-style: normal;
        line-height: 67px;
        img {
          margin-left: 10px;
        }
        @media (max-width: 1100px) and (min-width: 910px) {
          font-size: 40px;
          line-height: 55px;
        }

        @media (max-width: 910px) {
          font-size: 32px;
          line-height: 42px;

          img {
            transform: scale(0.6);
          }
        }
      }

      .img-text {
        display: flex;
        align-items: flex-start;
        width: 100%;
        margin-top: 18px;

        .text-info {
          margin-left: 17px;
          color: #000;
          font-style: normal;

          h1 {
            font-weight: 500;
            font-size: 20px;
            line-height: 22px;
          }

          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
          }
        }
        &:nth-child(3) {
          .text-info {
            margin-left: 13px;
          }
        }
      }
    }

    .links {
      position: absolute;
      bottom: 32px;
      width: 100%;
      @media screen and (max-width: 800px) {
        bottom: 20px;
      }
      @media (max-width: 750px) {
        display: none;
      }
    }
  }
  .block {
    display: block;
  }
}

.full-height {
  @media (max-width: 750px) {
    height: auto;
  }
}

@keyframes open {
  0% {
    height: 0;
  }

  100% {
    height: auto;
  }
}
