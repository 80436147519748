.wrapper {
  position: relative;
  display: fixed;
  width: 100vw;
  height: 100vh;
  background: var(--color-primary);

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    font-weight: 700;
    font-size: 30px;
    font-family: 'Neue Machina', sans-serif;
    transform: translate(-50%, -50%);
  }
}
