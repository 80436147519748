.wrapper {
  position: relative;

  .antSelector {
    width: 40% !important;
    height: auto !important;
    :global {
      .ant-select-selector {
        height: auto !important;
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
      }
    }
  }
}
