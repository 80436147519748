.icon {
  color: var(--content-dark);
  cursor: pointer;
}

.icon:hover {
  color: var(--icon-note-hover);
}

:global(.ant-popover) {
  position: relative;
  z-index: 10;

  :global(.ant-popover-arrow) {
    :global(.ant-popover-arrow-content) {
      background: white !important;

      --antd-arrow-background-color: white !important;
    }
  }

  :global(.ant-popover-inner) {
    color: var(--content-dark-8);
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    background: white !important;
    border: 1px solid var(--info-popover-borders);
    border-radius: 20px;
    box-shadow: 0 2px 2px var(--content-dark-15);

    :global(.ant-popover-inner-content) {
      padding: 15px;
      color: var(--content-white);

      p {
        margin: 0;
      }
    }
  }
}
