@mixin text {
  margin: 0;
  font-weight: 500;
  font-size: 24px;
  font-family: Work-sans, sans-serif;
  font-style: normal;
  line-height: 26px;
}

.wrapper {
  .left {
    display: none;
    align-items: center;
    height: 40px;
    .left-header {
      @include text();
      @media screen and (max-width: 750px) {
        display: none;
      }
    }
    @media screen and (max-width: 750px) {
      display: flex;
    }
  }

  .right-mobile {
    @include text();
    @media screen and (min-width: 750px) {
      display: none;
    }

    margin-bottom: 20px;
  }
  .right-header {
    display: flex;
    gap: 8px;
    align-items: center;
    .button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 8px !important;
    }
  }

  .title {
    margin-top: 40px;
  }
  .wrap {
    position: relative;
    height: 60px;
    padding-bottom: 14px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }
  @media screen and (max-width: 750px) {
    .wrap {
      align-items: center;
      height: 60px !important;
      padding-bottom: 10px;
    }
  }
}

@media screen and (max-width: 750px) {
  :global(.dropdown_overlay) {
    top: 70px !important;
    box-sizing: border-box !important;
    width: 100% !important;
    height: calc(100vh - 70px) !important;
    overflow: hidden !important;
    background: #f2f2f2 !important;
  }
  :global(.ant-menu) {
    border: 0 !important;
  }
}
