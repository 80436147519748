.wrapper {
  width: 100%;
  height: 100%;
  background: #fff;
  box-shadow: 0 0 20px #0000001a;
  transform-origin: top;
  user-select: none;

  :global(.ant-select) {
    // width: auto;
    height: auto;
  }
  :global(.ant-select-selector) {
    height: auto;
  }
}
