.wrapper {
  width: 100%;
  height: 100%;
  background: transparent;
  box-shadow: 0 0 20px #0000001a;
  transform-origin: top;
  user-select: none;

  :global(.ant-segmented) {
    position: fixed !important;
    bottom: 0 !important;
    z-index: 1 !important;
  }

  .leva-c-kWgxhW {
    display: inline;
  }
}
