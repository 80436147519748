.picker {
  position: absolute;
  top: 0;
  right: 18px;
  display: flex;
  align-items: center;
  height: 50px;
  border-radius: 4px;

  input {
    position: relative;
    width: 20px;
    height: 20px;
    padding: 0;
    overflow: hidden;
    border: none;
    border-radius: 4px;
  }

  input[type='color']::-webkit-color-swatch {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    border: none;
    cursor: pointer;
  }
  @media screen and (max-width: 576px) {
    height: 40px;
  }
}
