.wrapper {
  width: 100%;
  span {
    padding: 4px 4px !important;
  }
  input {
    width: 100% !important;
    text-align: left !important;
    background-color: transparent !important;
  }
}
