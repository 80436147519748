.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  .icons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 82px;
    height: 82px;
    margin-bottom: 20px;
    color: var(--icon-green);
    border-radius: 50%;
  }

  & h2 {
    font-weight: 700;
    font-size: 35px;
    font-family: 'Neue Machina', sans-serif;
    line-height: 38px;
    text-align: center;
  }

  .description {
    margin-bottom: 30px;
    color: #000;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    & span {
      color: #000;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
    }
  }

  .btn {
    padding: 17px 0;
    p {
      line-height: 24px !important;
    }
    &:last-child {
      margin-top: 10px;
    }
    &.outlines {
      padding: 0;
      border: 0;
      &:hover {
        box-shadow: 0 0 transparent;
      }
    }
  }
  .send {
    margin-top: 30px;
    color: #000;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Gordita', sans-serif;
    font-style: normal;
    line-height: 17px;
    text-align: center;
    cursor: pointer;
  }
}
