.wrapper {
  display: inline-block;

  .message {
    display: flex;
    align-items: center;
    margin-top: 4px;
    color: var(--input-error-color);

    span {
      margin-left: 5px;
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
    }
  }
}

:global(.ant-checkbox:hover .ant-checkbox-inner) {
  border-color: var(--content-dark);
}

:global(.ant-checkbox-wrapper:hover .ant-checkbox-inner) {
  border-color: var(--content-dark);
}

:global(.ant-checkbox-input:focus + .ant-checkbox-inner) {
  border-color: var(--content-dark);
}

:global(.ant-checkbox-wrapper) {
  gap: 7px;

  span {
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
  }
}

:global(.ant-checkbox-inner) {
  width: 18px;
  height: 18px;
  border-color: var(--content-dark);
}

:global(.ant-checkbox-checked::after) {
  border-color: var(--content-primary);
}

:global(.ant-checkbox-checked) {
  :global(.ant-checkbox-inner) {
    background-color: var(--content-primary);
    border: none;
  }

  :global(.ant-checkbox-inner::after) {
    left: 26%;
    border-color: var(--content-dark);
  }
}
