.wrapper {
  padding-top: 60px;
  background-color: transparent;
  .toggle {
    width: 100%;
    :global(.icon_wrapper) {
      left: 220px !important;
    }
  }

  .top-bar {
    position: fixed;
    top: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    margin-bottom: 0;
    padding: 0 40px;
    background-color: rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid #f2f2f2;

    @media screen and (max-width: 576px) {
      padding: 20px 30px;
    }

    &--btn {
      min-width: 192px;
      min-height: 33px;
      padding: 9px 0;
    }
  }

  .content {
    display: flex;
    justify-content: center;
    background-color: transparent;

    @media (max-width: 750px) {
      display: block;
    }

    :global(.ant-row) {
      width: 100%;
      margin: 0 !important;
    }

    :global(.ant-col) {
      width: 100%;
    }

    &-left {
      flex: 0.3;
      height: 100vh;
      padding: 40px;
      overflow: auto;
      background: var(--content-white);
      @media (max-width: 750px) {
        height: max-content;
        min-height: unset;
      }

      &--title {
        display: flex;
        gap: 10px;
        align-items: center;
        padding-left: 12px;
        a {
          color: black;
        }
        .title {
          font-size: 22px;
          line-height: 23px;
        }
      }
      &--divider {
        width: 1px;
        height: 20px;
        background-color: rgba(0, 0, 0, 0.08);
      }

      &-form {
        .subtitle {
          padding-left: 12px;
          :global(.tag_wrapper) {
            font-weight: 400;
          }
        }
        :global(.currency-select_before) {
          display: none !important;
        }
        :global(.currency-select_after) {
          display: none !important;
        }
        .currency-col {
          .currency-input {
            width: 100%;
          }
          :global(.input_text) {
            padding: 0 1px 0 0;
          }

          :global(.currency-select_wrapper) {
            padding: 0;
            padding-left: 3px;
            background: var(--content-white);
            border-radius: 10px;
          }

          :global(.ant-select-focused) {
            border: 1px solid transparent !important;
          }

          :global(.ant-select-arrow) {
            right: 3px;
          }
        }

        .toggle {
          width: 100%;
          .upload-row {
            .addUpload {
              label {
                border-radius: 10px !important;
              }
              p {
                &:nth-child(1) {
                  font-weight: 500;
                  opacity: 0.4;
                }
                &:nth-child(2) {
                  font-weight: 400;
                  opacity: 0.4;
                }
              }
              :global(.button_wrapper) {
                width: 95px;
                height: 38px;
                padding: 10px 30px 10px 10px;
                p {
                  font-size: 14px;
                  line-height: 15px;
                  opacity: 1;
                }
              }
            }
            img {
              object-fit: contain !important;
            }
          }
        }
      }
    }

    &-right {
      display: flex;
      flex: 0.7;
      flex-direction: column;
      align-items: center;
      height: 100vh;
      overflow: hidden;
      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 60px 60px 0 60px;
        @media (max-width: 850px) {
          padding: 25px;
        }

        @media (max-width: 550px) {
          padding: 15px;
        }
        .popover {
          display: flex;
          gap: 10px;
          align-items: center;
          justify-content: flex-start;
          p {
            margin: 0;
            font-size: 22px;
          }
        }
        .browser {
          width: auto;
        }
      }
    }
  }

  .metadata {
    display: flex;
    flex-direction: column;
    gap: 15px;

    &-btn {
      display: inline-block;
      width: 40%;
      min-width: 220px;
      margin-left: 12px;
      padding: 10px 5px;
      font-size: 14px;
      line-break: unset;

      & p {
        font-size: 14px;
      }
    }
  }
}
