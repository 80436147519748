.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 270px;
  margin: 0 auto;
  padding: 36px 0;
  .text {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    margin-top: 20px;
    text-align: center;
    .btn {
      width: 119px;
      height: 34px;
      padding: 0;
      & * {
        font-size: 14px !important;
      }
    }
  }
}
