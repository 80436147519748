.wrapper {
  width: 100%;
  margin-top: 12px;
  overflow: hidden;
  background-color: var(--content-white);
  border: 1px solid var(--border-color);
  border-radius: 20px;
  :global(.ant-table-thead) {
    tr {
      overflow: hidden;
      border-radius: 10px !important;
      th {
        border-bottom: 0;
        &:first-child {
          border-top-left-radius: 10px !important;
        }
        &:last-child {
          border-top-right-radius: 10px !important;
        }
      }
    }
  }
  .table {
    background-color: var(--content-white);
    user-select: none;
    // :global(.copy-)
    :global(.ant-table-row) {
      cursor: pointer;
      td {
        padding: 18px 22px 18px 0 !important;
      }
    }
    // :global(.)
    @media screen and (max-width: 750px) {
      :global(table) {
        width: 100% !important;
      }
      :global(.ant-table-thead) {
        width: 100% !important;
        th {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          &:nth-child(4) {
            &::before {
              display: none !important;
            }

            overflow: hidden !important;
            border-top-right-radius: 10px !important;
            border-bottom-right-radius: 10px !important;
          }
          &:nth-child(2) {
            display: none;
          }
          &:last-child {
            display: none !important;
          }
        }
      }
      :global(.ant-table-tbody) {
        width: 100% !important;

        td {
          &:last-child {
            display: none !important;
          }
          &:nth-child(2) {
            display: none;
          }
          &:nth-child(1) {
            width: 30% !important;
          }
          &:nth-child(3) {
            width: 45% !important;
          }
          &:nth-child(4) {
            overflow: hidden !important;
          }
        }
      }
      :global(.ant-table-row) {
        td {
          padding-top: 22.5px !important;
          padding-bottom: 22.5px !important;
        }
      }
      :global(.table_wrapper) {
        .ant-table-content {
          table {
            background-color: transparent !important;
          }
        }
      }

      :global(.tag_wrapper) {
        padding: 7px 21.5px 6px;
      }

      :global(.column) {
        width: max-content !important;
      }
    }
  }
  .empty {
    width: 100%;
    div {
      line-height: 24px !important;
    }
  }
}
