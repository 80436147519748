.container {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  padding: 10px;

  @media screen and (max-width: 750px) {
    grid-gap: 20px;
    grid-template-columns: repeat(1, 1fr);
    height: auto;
  }

  .left_card,
  .right_card {
    width: 100%;
  }
  .right_card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    @media screen and (max-width: 750px) {
      align-items: center;
      padding: 0;
    }
  }
  .links {
    display: none;
    width: 100%;
    @media (max-width: 750px) {
      display: block;
    }
  }
}
