.wrapper {
  width: 100%;
  margin-top: 12px;
  overflow: hidden;
  background-color: var(--content-white);
  border: 1px solid var(--border-color);
  border-radius: 20px;

  :global(.ant-table-thead) {
    tr {
      overflow: hidden;
      border-radius: 10px !important;
      th {
        border-bottom: 0;
        &:first-child {
          border-top-left-radius: 10px !important;
        }
        &:last-child {
          border-top-right-radius: 10px !important;
        }
      }
    }
  }

  .table {
    :global(.ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table) {
      margin: 0 !important;
    }

    background-color: var(--content-white);
    user-select: none;

    :global(.ant-table-row) {
      td {
        padding: 18px 22px 18px 0 !important;
      }

      &.ant-table-expanded-row {
        background-color: var(--light-grey);
        td {
          padding: 12px 16px !important;
        }
      }
    }

    :global(.ant-table-expanded-row > td > .ant-table-wrapper) {
      margin: 0 16px;
      padding: 8px;
      background-color: var(--content-white);
      border: 1px solid var(--border-color);
      border-radius: 10px;
    }

    @media screen and (max-width: 750px) {
      :global(table) {
        width: 100% !important;
      }
      :global(.ant-table-thead) {
        width: 100% !important;
        th {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          &:nth-child(4) {
            &::before {
              display: none !important;
            }

            overflow: hidden !important;
            border-top-right-radius: 10px !important;
            border-bottom-right-radius: 10px !important;
          }
          &:nth-child(2) {
            display: none;
          }
          &:last-child {
            display: none !important;
          }
        }
      }
      :global(.ant-table-tbody) {
        width: 100% !important;
        td {
          &:last-child {
            display: none !important;
          }
          &:nth-child(2) {
            display: none;
          }
          &:nth-child(1) {
            width: 30% !important;
          }
          &:nth-child(3) {
            width: 45% !important;
          }
          &:nth-child(4) {
            overflow: hidden !important;
          }
        }
      }
      :global(.ant-table-row) {
        td {
          padding-top: 22.5px !important;
          padding-bottom: 22.5px !important;
        }
      }
      :global(.table_wrapper) {
        .ant-table-content {
          table {
            background-color: transparent !important;
          }
        }
      }
      :global(.tag_wrapper) {
        padding: 7px 21.5px 6px;
      }
      :global(.column) {
        width: max-content !important;
      }
      :global(.copy-board_copied) {
        margin-top: 0 !important;
        p {
          display: none !important;
        }
      }
    }
  }

  .empty {
    width: 100%;
    div {
      line-height: 24px !important;
    }
  }
}
