.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 40px;
}
.links {
  display: none;
  margin: 50px 0;
  @media screen and (max-width: 750px) {
    display: block;
  }
}
