.wrapper {
  display: flex;
  gap: var(--gutter, 10px);
  align-items: center;
  justify-content: flex-start;
  color: var(--content-dark);
  font-weight: var(--font-weight, 500);
  font-size: var(--font-size, 22px);
  font-style: normal;
  line-height: var(--line-height, 14px);
}
