.wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: stretch;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  .input {
    width: 100%;
  }
  .title {
    color: var(--content-dark);
    font-weight: 700;
    font-size: 35px;
    font-family: 'Neue Machina', sans-serif;
    font-style: normal;
    line-height: 34px;
    text-align: center;
  }
  .description {
    color: #000;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }
  .btn {
    width: 100%;
    margin-top: 30px;
    padding: 17px 0;
  }
}
