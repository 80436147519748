.wrapper {
  width: 100%;
  margin-top: 24px;
  // img {
  //   width: 102px;
  //   height: 102px;
  //   object-fit: contain;
  // }
  .message {
    display: flex;
    align-items: center;
    margin-top: 8px;
    color: var(--input-error-color);

    span {
      margin-left: 5px;
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
    }
  }

  .card {
    img {
      width: 104px;
      height: 90px;
      object-fit: cover;
    }
  }

  .ant-upload.ant-upload-select-picture-card {
    height: auto !important;
  }

  label {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 29px 0;
    background-color: var(--content-white);
    border: 1px dashed var(--content-dark-40);
    cursor: pointer;

    p {
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: center;
      color: var(--content-dark-40);
      font-weight: 400;
      font-size: 12px;
      font-style: normal;
      line-height: 13px;
    }

    .btn {
      padding: 10px;
      font-size: 14px;
      border-radius: 10px;
    }
  }

  .logo {
    position: relative;
    width: 140px;
    height: 140px;
    overflow: hidden;
    border-radius: 10px;

    img {
      width: 100%;
      height: 100%;
    }

    .icon {
      position: absolute;
      top: 5px;
      right: 5px;
      background-color: var(--content-white);
      border-radius: 50%;
      cursor: pointer;
    }
  }

  input {
    display: none;
  }
}
