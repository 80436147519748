.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  height: 100%;
  margin: 0 auto;
  .title {
    margin-top: 40px;
    color: var(--content-dark);
    font-weight: 700;
    font-size: 35px;
    font-family: 'Neue Machina', sans-serif;
    font-style: normal;
    line-height: 36px;
    text-align: center;
  }
  .input {
    width: 100%;
    min-width: 100%;
  }
  .btn {
    width: 100%;
    min-height: 60px;
    margin-top: 30px;
    padding: 17px 0;
    @media screen and (max-width: 576px) {
      padding: 15px;
    }
  }
}
