.wrapper {
  width: 100%;

  :global(.ant-card-extra) {
    width: 100%;
    margin-left: 0 !important;
  }

  .message {
    display: flex;
    align-items: center;
    margin-top: 8px;
    color: var(--input-error-color);

    span {
      margin-left: 5px;
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
    }
  }

  label {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 29px 0;
    background-color: var(--content-white);
    border: 1px dashed var(--content-dark-40);
    cursor: pointer;

    p {
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: center;
      color: var(--content-dark-40);
      font-weight: 400;
      font-size: 12px;
      font-style: normal;
      line-height: 13px;
    }

    .btn {
      padding: 10px;
      font-size: 14px;
      border-radius: 10px;
    }
  }

  .logo {
    position: relative;
    width: 138px;
    height: 138px;
    overflow: hidden;
    border-radius: 10px;

    img {
      width: 100%;
      height: 100%;
    }

    .icon {
      position: absolute;
      top: 5px;
      right: 5px;
      background-color: var(--content-white);
      border-radius: 50%;
      cursor: pointer;
    }
  }
  input {
    display: block;
    box-sizing: border-box;
    width: 90px;
    // margin-left: 8px;
    font: inherit;
    text-align: center;
    text-transform: uppercase;
    background: #eee;
    border: 1px solid #ddd;
    border-radius: 4px;
    outline: none;
  }

  .upload {
    display: grid; /* 1 */
    grid-auto-rows: 140px; /* 3 */
    grid-gap: 12px; /* 4 */
    grid-template-columns: repeat(3, 110px); /* 2 */
    @media screen and (max-width: 576px) {
      grid-auto-rows: 130px; /* 3 */
      grid-gap: 10px; /* 4 */
      grid-template-columns: repeat(2, 100px); /* 2 */
    }
  }

  input:focus {
    border-color: #4298ef;
  }
}

.ant-upload-picture-card-wrapper {
  width: auto !important;
}
