.wrapper {
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
    height: 100%;
    margin: 0 auto;
    .title {
      margin-top: 40px;
      color: var(--content-dark);
      font-weight: 700;
      font-size: 35px;
      font-family: 'Neue Machina', sans-serif;
      font-style: normal;
      line-height: 36px;
      text-align: center;
    }
    .input {
      width: 100%;
      min-width: 100%;
    }
    .btn {
      width: 100%;
      margin-top: 30px;
      padding: 17px 0;
    }
  }
  .message {
    display: flex;
    flex-direction: column;
    align-items: center;
    .icons {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 82px;
      height: 82px;
      margin-bottom: 30px;
      background: rgba(96, 255, 131, 0.15);
      border-radius: 50%;
    }
    & h2 {
      font-weight: 700;
      font-size: 35px;
      font-family: 'Neue Machina', sans-serif;
      line-height: 38px;
      text-align: center;
    }

    & p {
      text-align: center;

      & span {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
      }
    }
    .btn {
      padding: 17px 0;
      p {
        line-height: 24px !important;
      }
    }
    .send {
      margin-top: 30px;
      color: #000;
      font-weight: 500;
      font-size: 16px;
      font-family: 'Gordita', sans-serif;
      font-style: normal;
      line-height: 17px;
      text-align: center;
      cursor: pointer;
    }
  }
}
