.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: stretch;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  .input {
    width: 100%;
  }
  .title {
    color: var(--content-dark);
    font-weight: 700;
    font-size: 35px;
    font-family: 'Neue Machina', sans-serif;
    font-style: normal;
    line-height: 36px;
    text-align: center;
  }
  .outlines {
    width: max-content;
    padding: 0;
    border: 0;
    border-radius: 0;
    &:hover {
      box-shadow: 0 0 transparent;
    }
    p {
      margin: 0;
      font-size: 14px !important;
      line-height: 20px !important;
      text-align: start;
      cursor: pointer;
    }
  }
  .btn {
    width: 100%;
    min-height: 60px;
    margin-top: 10px;
    padding: 17px 0;
    @media screen and (max-width: 576px) {
      padding: 15px;
    }
  }
}
