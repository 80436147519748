@import '~antd/dist/antd.min.css';
@import '~comfi-ui-kit/lib/globals.css';
@import '~comfi-ui-kit/lib/index.css';
@import 'react-loading-skeleton/dist/skeleton.css';
@import './themes.scss';

.input_wrapper {
  width: 100% !important;
}

*::selection {
  color: var(--content-dark);
  background-color: var(--content-primary);
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--content-dark-40);
  border-radius: 100px;
}

.ant-modal-header {
  padding: 20px 30px;
}
