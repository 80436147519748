.wrapper {
  position: relative;
  width: 100%;
  .container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    background: var(--content-white);
    border: 1px solid var(--select-input);
    border-radius: 10px;
    transition: all linear 0.225s;

    &.active {
      border: 1px solid var(--content-dark);
    }

    &.error {
      border: 1px solid var(--input-error-color);
      box-shadow: inset var(--status-red-light) 0 0 0 3px, var(--status-red-light) 0 0 0 3px;
    }

    &.disabled {
      background: var(--content-dark-03);
      border: 1px solid var(--content-dark-20);
    }

    .input {
      order: 2;
      width: 100%;
      height: 50px;
      padding: 0 0 0 21px;
      overflow: hidden;
      color: var(--content-dark);
      font-weight: 500;
      font-size: 14px;
      font-style: normal;
      line-height: 15px;
      background: transparent;
      border: 0;
      border-radius: 10px;
      font-kerning: none;

      &::placeholder {
        visibility: hidden;
      }

      &:focus ~ .label,
      &:not(:placeholder-shown) ~ .label {
        top: -11px;
        color: var(--content-dark);
      }

      &:not(:placeholder-shown) ~ .prefix {
        opacity: 1;
      }

      &:focus ~ .prefix,
      &:not(:placeholder-shown) ~ .prefix {
        opacity: 1;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        font-size: 14px;
        line-height: 15px;
        background: var(--content-white);
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: var(--content-dark);
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        margin: 0;
        appearance: none;
      }

      &:focus ~ .wrapper {
        border: 1px solid var(--content-dark);
        box-shadow: 0 0 transparent;
      }

      &.is-prefix {
        padding: 0;
      }

      @media screen and (max-width: 576px) {
        left: 13px;
        height: 40px;
        font-size: 13px;
      }
    }

    .prefix {
      display: flex;
      display: inline-block;
      align-items: center;
      order: 1;
      height: 50px;
      margin-right: 2px;
      padding: 17.5px 0 17.5px 21px;
      color: var(--content-dark);
      font-weight: 500;
      font-size: 14px;
      font-style: normal;
      line-height: 15px;
      opacity: 0;
      transition: all linear 0.225s;
      font-kerning: none;

      @media screen and (max-width: 576px) {
        left: 18px;
        height: 40px;
        padding: 12.5px 0 12.5px 18px;
        font-size: 13px;
      }

      &.active {
        opacity: 1;
      }
    }
    @media screen and (max-width: 576px) {
      height: 40px;
    }
  }

  .keyboard {
    margin-top: 8px;
    color: var(--content-dark-40);
    font-weight: 400;
    font-size: 12px;
    font-style: normal;
    line-height: 13px;
  }

  .text {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 18.5px 0 5px;
    color: var(--content-dark);
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    transition: all linear 0.225s;
    @media screen and (max-width: 576px) {
      height: 40px;
      padding: 0 15.5px 0 5px;
      font-size: 13px;
    }
  }

  .label {
    position: absolute;
    top: 14px;
    left: 16px;
    padding: 3px 5px;
    color: var(--select-placeholder-color);
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    line-height: 15px;
    background: var(--content-white);
    border-radius: 3px;
    opacity: 1;
    transition: all linear 0.225s;

    &.active {
      top: -11px;
      color: var(--content-dark);
    }

    &.error {
      color: var(--input-error-color);
    }
    @media screen and (max-width: 576px) {
      top: 9px;
      left: 13px;
      font-size: 13px;
    }
  }

  .message {
    display: flex;
    gap: 5px;
    align-items: center;
    margin-top: 8px;
    color: var(--input-error-color);

    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
      transition: all linear 0.225s;
    }
  }
}
