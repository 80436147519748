.wrapper {
  position: relative;
  display: grid;
  padding: 20px 77px;
  overflow: hidden;
  border: 1px solid transparent;
  border-radius: 15px;
  cursor: pointer;
  transition: all linear 0.225s;
  place-items: center;

  &:hover {
    border: 1px solid #000;
    box-shadow: 2px 2px 0 #000;
  }

  .ripples {
    position: absolute;
    background: var(--content-white);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: animate 1s linear infinite;
    pointer-events: none;
  }

  .wrap {
    display: flex;
    gap: 10px;
    align-items: center;
    box-sizing: border-box;

    .p {
      position: relative;
      z-index: 10;
      margin: 0;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
    }
  }

  &-block {
    width: 100%;
  }

  &--type {
    &-link {
      background-color: transparent;
    }

    &-green {
      background-color: var(--content-primary);

      &:disabled {
        background-color: var(--button-disabled-color);
      }
    }

    &-grey {
      background-color: var(--border-color);
    }

    &-black {
      color: var(--content-white);
      background-color: var(--content-dark);

      &:hover {
        border: 1px solid #605f5f;
        box-shadow: 2px 2px 0 #605f5f;
      }
    }

    &-outline {
      background-color: transparent;
      border: 1px solid transparent;
      &:hover {
        background-color: var(--content-primary);
      }
    }

    &-purple {
      color: var(--content-white);
      font-weight: 500;
      font-size: 18px;
      line-height: 19px;
      background: var(--button-background-purple);
    }
  }

  &-disabled {
    border-color: transparent !important;
    box-shadow: none !important;
    cursor: not-allowed;

    &:hover {
      border-color: transparent !important;
    }
  }

  .dot-pulse {
    position: relative;
    left: -9999px;
    z-index: 10;
    width: 10px;
    height: 10px;
    color: var(--content-dark);
    background-color: var(--content-dark);
    border-radius: 5px;
    box-shadow: 9999px 0 0 -5px var(--content-dark);
    animation: dot-pulse 1.5s infinite linear;
    animation-delay: 0.25s;
  }

  .dot-pulse::before,
  .dot-pulse::after {
    position: absolute;
    top: 0;
    display: inline-block;
    width: 10px;
    height: 10px;
    color: var(--content-dark);
    background-color: var(--content-dark);
    border-radius: 5px;
    content: '';
  }

  .dot-pulse::before {
    box-shadow: 9984px 0 0 -5px var(--content-dark);
    animation: dot-pulse-before 1.5s infinite linear;
    animation-delay: 0s;
  }

  .dot-pulse::after {
    box-shadow: 10014px 0 0 -5px var(--content-dark);
    animation: dot-pulse-after 1.5s infinite linear;
    animation-delay: 0.5s;
  }
}

@keyframes animate {
  0% {
    width: 0;
    height: 0;
    opacity: 0.5;
  }

  100% {
    width: 500px;
    height: 500px;
    opacity: 0;
  }
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px var(--content-dark);
  }

  30% {
    box-shadow: 9984px 0 0 2px var(--content-dark);
  }

  60%,
  100% {
    box-shadow: 9984px 0 0 -5px var(--content-dark);
  }
}

@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px var(--content-dark);
  }

  30% {
    box-shadow: 9999px 0 0 2px var(--content-dark);
  }

  60%,
  100% {
    box-shadow: 9999px 0 0 -5px var(--content-dark);
  }
}

@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px var(--content-dark);
  }

  30% {
    box-shadow: 10014px 0 0 2px var(--content-dark);
  }

  60%,
  100% {
    box-shadow: 10014px 0 0 -5px var(--content-dark);
  }
}
