.main {
  display: flex;
  gap: 0;
  min-height: 100vh;
  background-color: #fcfcfc;
  @media screen and (max-width: 750px) {
    flex-direction: column-reverse;
    min-height: 100vh;
  }

  &-sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    width: 340px;
    padding: 18px 40px;
    overflow-y: auto; // Added to allow scrolling
    border-right: 1px solid var(--border-color);
    transition: all ease-out 0.5s;

    :global(.ant-menu) {
      border: none !important;
    }

    &-top {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: left;
      width: 100%;
      img {
        width: 45px;
        height: 45px;
        margin-right: 16px;
      }
      p {
        margin: 0 !important;
      }
    }

    .switch_mode {
      display: flex;
      gap: 4px;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 35px;
      @media screen and (max-width: 750px) {
        display: none;
      }
      .test {
        color: #fc7a51 !important;
      }
      :global(.ant-switch-checked) {
        background-color: #fc7a51 !important;
      }
    }

    @media screen and (min-width: 750px) {
      height: 100vh;
    }
    @media screen and (max-width: 750px) {
      padding: 10px;
    }
    @media screen and (max-width: 960px) {
      width: 280px;
    }
    @media screen and (max-width: 800px) {
      width: 260px;
    }
  }

  .main-sidebar-open {
    width: 154px;
  }

  &-content {
    flex: 1;
    min-height: 100vh; // Ensure the content takes at least the full viewport height
    padding: 14px 40px;
    overflow-y: auto; // Enable scrolling for overflowing content
    transition: all linear 0.05s;
    @media screen and (max-width: 750px) {
      padding: 10px;
      overflow: initial;
    }
  }
}
